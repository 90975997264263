<template>
  <footer class="footer">
    <div class="footer__wrapper">
      <div class="footer__content">
        <div class="footer__logo">
          <TheLogoFooter />
        </div>
        <div class="footer__box">
          <div class="footer__leftblock">
            <div class="footer__item">
              <a :href="kontakt.googlemaps" target="_blank" class="footer__a"
                >{{ kontakt.address.street }}
              </a>
            </div>
            <div class="footer__item">
              <a :href="kontakt.googlemaps" target="_blank" class="footer__a"
                >{{ kontakt.address.plz }} {{ kontakt.address.location }}
              </a>
            </div>
          </div>
          <div class="footer__middleblock">
            <div class="footer__item">
              Dienstag – Donnerstag: 12:00 – 15:00 Uhr
            </div>
            <div class="footer__item">
              Montag – Donnerstag: 18:00 – 23:00 Uhr
            </div>
            <div class="footer__item">
              Freitag – Samstag: 18:00 – 24:00 Uhr
            </div>
          
          </div>
          <div class="footer__middleblock">
            <div class="footer__item">
              <a :href="getPhone" class="footer__a">{{ kontakt.phone }}</a>
            </div>
            <div class="footer__item">
              <a :href="getMail" class="footer__a"> {{ kontakt.email }}</a>
            </div>
            <div class="navkontaktbtns__item footer__instagram">
              <a
                href="https://www.instagram.com/brasserie_thi"
                target="_blank"
                class="navkontaktbtns__a"
              >
                <img
                  :src="
                    getPath('assets/img/svg/THI-Icons_Website-Instagram.svg')
                  "
                  alt=""
                  class="navkontaktbtns__img"
                />
              </a>
            </div>
          </div>
          <div class="footer__rightblock">
            <div class="footer__item">
              <router-link :to="prefixPath('datenschutz')" class="footer__a">
                Datenschutz
              </router-link>
            </div>
            <div class="footer__item">
              <router-link :to="prefixPath('impressum')" class="footer__a">
                Impressum
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import getPathFunction from "@/utilities/composition/getPathFunction";
import contactInformation from "../../../utilities/siteinformation/contactInformation";
import TheLogoFooter from "../../ui/logo/TheLogoFooter.vue";
export default {
  components: { TheLogoFooter },
  setup() {
    const kontakt = contactInformation;
    const publicPath = process.env.BASE_URL;
    const getMail = "mailto:" + kontakt.email;
    const getPhone = "tel:" + kontakt.phone;

    const { getPath } = getPathFunction();

    function prefixPath(el) {
      return publicPath + el;
    }
    return { kontakt, getMail, getPhone, prefixPath, getPath };
  },
};
</script>

<style lang="scss">
.footer {
  padding-top: 60px;
  padding-bottom: 60px;
  width: 100%;
  background-color: $main-color-1;

  margin-bottom: 0 !important;
}

.footer__box {
  margin-top: 60px;
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: stretch;
  flex-wrap: wrap;
}

.footer__instablock {
  display: flex;
  flex-direction: column;
  flex: 1 0 100%;

  padding: 20px 10px;

  @media screen and (min-width: $medium) {
    flex: 1 0 0 100%;
  }
}
.footer__leftblock {
  display: flex;
  flex-direction: column;
  flex: 1 0 100%;

  padding: 20px 10px;

  @media screen and (min-width: $medium) {
    flex: 1 0 33.33%;
  }
}

.footer__middleblock {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1 0 100%;

  padding: 20px 10px;

  @media screen and (min-width: $medium) {
    flex: 1 0 33.33%;
  }
}
.footer__rightblock {
  display: flex;
  flex-direction: column;
  flex: 1 0 100%;

  padding: 20px 10px;

  @media screen and (min-width: $medium) {
    flex: 1 0 33.33%;
  }
}
.footer__item {
  text-align: center;

  font-size: 19px;
  line-height: 25px;
  letter-spacing: 0.13px;
  color: $main-color-4;
  text-transform: lowercase;
  opacity: 1;

  @media screen and (min-width: $medium) {
    font-size: 21px;
    line-height: 27px;
  }
}
.footer__a {
  display: block;
  text-decoration: none;
  color: inherit;
  font-size: inherit;
  line-height: inherit;
  white-space: nowrap;
  transition: 0.2s;

  :not(.is-mobile) &:hover {
    outline: none;
    color: $main-color-2;
  }
}

.footer__logo {
  width: 71px;
  height: 121px;
  margin: 0 auto;
}

.footer__instagram{
     margin-top: 10px;
}
</style>
