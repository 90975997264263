<template>
  <div class="divider" ref="divider">
    <div class="divider__border"></div>
  </div>
</template>

<script>
//gsap
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { onBeforeUnmount, onMounted, ref } from "@vue/runtime-core";

export default {
  setup() {
    const divider = ref(null);
    onMounted(() => {
      let scrollT = null;

      let el = divider.value;

      let tl = gsap.timeline({
        // yes, we can add it to an entire timeline!
      });

      // tl.set(el, { y: "50%", opacity: 0, rotation: 0.02, force3D: true });
      tl.from(el, {
        scaleX: 0,
        duration: 0.5,
        ease: "power2.out",
      });

      scrollT = ScrollTrigger.create({
        // scroller: "#main__content",
        animation: tl,
        trigger: el,
        scrub: 2,
        start: "top 90%",
        end: "top 50%",
        // markers: { startColor: "green", endColor: "red", fontSize: "12px" },

        // onToggle: (self) =>
        // onUpdate: (self) => {
        //   // console.log(
        //   //   "progress:",
        //   //   self.progress.toFixed(3),
        //   //   "direction:",
        //   //   self.direction,
        //   //   "velocity",
        //   //   self.getVelocity()
        //   // );
        // },
      });
      scrollT.refresh();
    });

    onBeforeUnmount(() => {});

    return { divider };
  },
};
</script>

<style lang="scss">
.divider {
  width: 100%;
}
.divider__border {
  width: 100%;
  height: 2px;
  background-color: $main-color-2;
}
</style>
