<template>
  <div class="header" ref="header">
    <div class="header__wrapper" ref="headerWrapper">
      <GalleryPic
        class="header__img"
        :imgpath="imgpath"
        :imgposition="imgposition"
      />
    
    </div>
  </div>
</template>

<script>
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

import GalleryPic from "@/components/common/gallery/GalleryPic.vue";

import getPathFunction from "@/utilities/composition/getPathFunction";
import { onMounted, ref } from "@vue/runtime-core";
export default {
  components: { GalleryPic },
  props: { imgpath: String, imgposition: String },
  setup() {
    const { setBackGroundPath } = getPathFunction();


    const headerWrapper = ref(null);
    const header = ref(null);
    let scrollT = null;
    onMounted(() => {
      //startTween

      

      let el = headerWrapper.value;
      let headerImg= headerWrapper.value.querySelector(".header__img");

      let tl = gsap.timeline({
        // yes, we can add it to an entire timeline!
      });


      gsap.from(headerImg,2, {scale:1.1, opacity:0, rotation:0.02, ease:"power2.out"});


      tl.set(el, { y: 0, rotation: 0.02, force3D: true });
      tl.to(el, {
        y: "50%",
        opacity: 1,
        rotation: 0,
        duration: 0.5,
        ease: "none",
      });

      scrollT = ScrollTrigger.create({
        // scroller: "#main__content",
        animation: tl,
        trigger: header.value,
        scrub: true,
        start: "top 0",
        // endTrigger: "#top 20%",
        end: "bottom 0%",
        // onToggle: (self) =>
        // onUpdate: (self) => {
        //   // console.log(
        //   //   "progress:",
        //   //   self.progress.toFixed(3),
        //   //   "direction:",
        //   //   self.direction,
        //   //   "velocity",
        //   //   self.getVelocity()
        //   // );
        // },
      });
      scrollT.refresh();
    });


    return { setBackGroundPath, headerWrapper, header };
  },
};
</script>

<style lang="scss">
.header {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.header__wrapper {
  width: 100%;
  height: 100%;
}
.header__img {
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
