<template>
  <div
    class="gallerypic"
    :style="{
      backgroundImage: setBackGroundPath(imgpath),
      backgroundPosition: imgposition,
    }"
    alt=""
  ></div>
</template>

<script>
import getPathFunction from "@/utilities/composition/getPathFunction";

export default {
  props: { imgpath: String, imgposition: String, motion: String },
  setup() {
    const { setBackGroundPath } = getPathFunction();

    return { setBackGroundPath };
  },
};
</script>

<style lang="scss">
.gallerypic {
  position: relative;
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
