<template>
  <div class="thelogofooter">
    <svg
      version="1.1"
      class="thelogofooter__logo"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 457.2 781.3"
      style="enable-background:new 0 0 457.2 781.3;"
      xml:space="preserve"
    >
      <path
        class="st0"
        d="M232.6,410.5V541c0,3.9-3.2,7.1-7.1,7.1s-7.1-3.2-7.1-7.1V410.5c0-3.9,3.2-7.1,7.1-7.1S232.6,406.6,232.6,410.5
	L232.6,410.5z M314.9,301c-3.9,0-7.1,3.2-7.1,7.1v47.8h-75.2v-96.9h60c3.9,0,7.1-3.2,7.1-7.1s-3.2-7.1-7.1-7.1H158.5
	c-3.9,0-7.1,3.2-7.1,7.1s3.2,7.1,7.1,7.1h60v96.9h-75.2V308c0-3.9-3.2-7.1-7.1-7.1s-7.1,3.2-7.1,7.1v204.6c0,3.9,3.2,7.1,7.1,7.1
	s7.1-3.2,7.1-7.1V369.9h164.5v142.7c0,3.9,3.2,7.1,7.1,7.1s7.1-3.2,7.1-7.1V308C322,304.1,318.8,301,314.9,301z M399.1,391.2
	c0,133.7-77.8,242.5-173.5,242.5S52.1,525,52.1,391.2s77.8-242.5,173.5-242.5S399.1,257.5,399.1,391.2L399.1,391.2z M385,391.2
	c0-126-71.5-228.5-159.4-228.5c-87.9,0-159.4,102.5-159.4,228.5s71.5,228.5,159.4,228.5C313.5,619.7,385,517.2,385,391.2L385,391.2z
	"
      />
      <path
        class="st1"
        d="M38.5,115.5c0.8-5-0.8-9.3-4.7-12.7c-3.7-3.2-7.8-4-12.2-2.6c-4,1.3-8.3,4.7-12.7,9.9L0,120.5l43.5,37.3
	L53.6,146c9.2-10.8,10-21.8,1.9-28.7C50.4,112.9,44.3,112.3,38.5,115.5L38.5,115.5z M10.3,116.6l1.8-2.1c2.8-3.2,5.7-5.3,8.4-6
	c2.6-0.7,4.9-0.1,6.9,1.6c2.3,1.9,3.5,4,3.7,6.4c0.3,3.2-1.5,7.1-5.2,11.4l-1,1.2L10.3,116.6z M48.4,143.9l-2.7,3.1l-17-14.6
	l3.9-4.5c2.8-3.3,6-5.1,9.2-5.2c2.8-0.1,5.6,1,7.9,3.3C56.1,132.1,52.5,139.1,48.4,143.9z M86.9,64.1c-3.5-4.5-8-7-13-7.3
	c-5.7-0.4-11.9,2-18.3,7l-8.7,6.8l35.3,45.1l7.6-5.9L78.5,95.5l1.5-1.1c1.6-1.2,2.9-2.5,4.2-3.8l19.5,8.2l8.3-6.5l-22.4-9.5
	C92.3,76.3,91.4,69.9,86.9,64.1L86.9,64.1z M75.3,91.5L57.5,68.7l0.4-0.3c4.3-3.4,8.5-4.7,12.5-3.9c3.8,0.7,7.3,3.5,9.6,7.5
	C84.1,79,82.5,85.5,75.3,91.5z M147.3,62.2l8.3,8l9.5-4.3l-44.5-42.7l-9.5,4.3l7.5,7.3l2.8,51l5-2.3l-0.6-11.5L147.3,62.2z
	M125.6,66.4l-1.4-26.5l19.2,18.5L125.6,66.4z M207.5,44.2c0.4,3.9-1.1,8.1-4.1,11.6c-2.6,3-6.4,5-11.1,5.9
	c-1.3,0.2-2.5,0.4-3.8,0.4c-5.1,0-9.7-2-13-5.7l-0.5-0.6l5.4-7.7l0.8,1.1c0.5,0.8,1.2,1.5,2.2,2.5c3.8,3.6,8.6,4.9,12.2,3.5
	c1.8-0.7,2.9-1.8,3.4-3.3c0.8-2.1,0.3-4.9-1.3-7.9c-1.9-3.5-6.5-7.5-13.4-11.6l-0.9-0.6c-3.9-2.3-8.3-4.9-10.4-9.9
	c-1.6-3.8-1.6-7.4,0-10.7c1.9-4,5.6-6.7,8.6-7.7c6.9-2.2,12.7-0.4,15.6,1.9l0.7,0.6l-3.7,5.2l-0.8-0.6c-3-2.3-6.5-3.4-9.2-2.9
	c-1.2,0.2-2.1,0.6-2.7,1.3c-1.6,1.7-1.3,4.3-1.1,5.4c1,4.1,4.3,6.1,8.4,8.6l1.8,1.1C203.9,32.3,206.8,37.5,207.5,44.2z M246,22.1
	c11,11.3,12.6,17.1,11.6,23.7c-0.5,3.9-3,7.6-6.7,10.2c-2.9,2.1-6.6,3.1-10.8,3.1c-0.5,0-1,0-1.5,0c-6.6-0.3-12.2-3.8-15-9.2
	l-0.3-0.7l7.1-6.2l0.5,1.3c0.3,0.9,0.8,1.8,1.5,3c2.8,4.4,7.1,6.8,11,6.3c1.9-0.3,3.3-1.1,4.1-2.4c1.2-1.8,1.5-4.7,0.6-8
	c-1-3.8-4.5-8.8-10.2-14.5l-0.8-0.8c-3.2-3.1-6.9-6.7-7.7-12.1c-0.6-4,0.3-7.6,2.6-10.4c2.8-3.4,7.1-5.2,10.2-5.4
	c7.3-0.5,12.4,2.7,14.6,5.6l0.5,0.7l-4.8,4.2l-0.6-0.8c-2.4-2.9-5.5-4.8-8.3-5c-1.2-0.1-2.2,0.1-3,0.6c-2,1.2-2.3,3.9-2.4,5
	c0,4.2,2.7,6.9,6.1,10.4L246,22.1z M293.5,35.2l19.5,6.7l-1.7,4.8L291.9,40l-7.4,21.3l24.3,8.4l-1.7,4.8L273.8,63l18.7-54.1
	l33.4,11.5l-1.7,4.9l-24.3-8.4L293.5,35.2z M372.7,70.1c3.1-4.8,4-9.8,2.7-14.7c-1.5-5.5-5.7-10.6-12.6-15.1l-9.3-6l-31.2,48
	l8.1,5.2l9.9-15.2l1.5,1c1.7,1.1,3.3,2,4.9,2.7l-1.5,21.1l8.9,5.8l1.8-24.3C362.9,79.2,368.7,76.3,372.7,70.1L372.7,70.1z
	M359.3,44.1c4.6,3,7.2,6.5,7.7,10.5c0.5,3.8-0.9,8-4,11.6c-5.3,6.1-12,6.7-19.9,1.8l15.7-24.2L359.3,44.1z M413.8,79.9L375.1,122
	l-7-6.5l38.8-42.1L413.8,79.9z M457.2,128.4l-4,3.2l-16.3-19.9l-15,12.2l13,16l-4,3.2l-13-16l-17.5,14.3l16.3,19.9l-4,3.2
	l-22.3-27.3l44.4-36.2L457.2,128.4z M145,724h43.7v5.1h-17.1v52.2h-9.6v-52.2H145V724z M244.9,724h9.6v57.3h-9.6v-27.7h-24.4v27.7
	h-9.6V724h9.6v24.5h24.4L244.9,724L244.9,724z M281.3,724h9.6v57.3h-9.6V724z"
      />
    </svg>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.thelogofooter {
  width: 100%;
  height: 100%;
}
.thelogofooter__logo {
  width: 100%;
  height: 100%;
}
.st0 {
  fill: #ca9e67;
}
.st1 {
  fill: #ffffff;
}
</style>
